/* eslint-disable no-unused-expressions */
import { request } from '@/api'
import { gaPurchase, gaViewItemList } from '@/ga'
import { i18n } from '@/translations'
import { EstimateCreateResult, GooseItemPrice, SubscriptionCreateResult } from '@/types/signUpModels'
import { getCamelCase, parseUserResponse } from '@/utils'

const EURO_COUNTRIES = ['AT', 'BE', 'BG', 'CY', 'CZ', 'DE', 'DK', 'EE', 'EL', 'ES', 'FI', 'FR', 'HR', 'HU', 'IE', 'IT', 'LT', 'LU', 'LV', 'MT', 'NL', 'PL', 'PT', 'RO', 'SE', 'SI', 'SK']
const STATE_KEY = 'sign-up-state'

export default {
  state: () => {
    const data = localStorage.getItem(STATE_KEY)
    if (data) {
      localStorage.removeItem(STATE_KEY)
      return JSON.parse(data)
    } else {
      return {
        deal: null,
        deals: null,
        loading: true,
        email: null,
        emailError: false,
        hasCyberAlarm: false,
        couponCode: null,
        currencyCode: 'EUR',
        country: 'NL',
        estimate: null,
        paymentMethods: null,
        signuptoken: null,
      }
    }
  },

  getters: {
    deal(state): GooseItemPrice {
      return state.deal
    },
    deals(state): GooseItemPrice[] {
      return state.deals
    },
    loading(state): boolean {
      return state.loading
    },
    email(state): string {
      return state.email
    },
    emailError(state): boolean {
      return state.emailError
    },
    addon(state): any {
      return state.deal
        .applicableItemPrices[0]
    },
    hasCyberAlarm(state): boolean {
      return state.hasCyberAlarm
    },
    couponCode(state): string {
      return state.couponCode
    },
    couponValid(state): boolean {
      if (!state.estimate || !state.couponCode) return undefined
      return state.estimate.appliedCouponCode === state.couponCode
    },
    currencyCode(state): string {
      return state.currencyCode
    },
    estimate(state): EstimateCreateResult {
      return state.estimate
    },
    signuptoken(state) : string {
      return state.signuptoken
    },
  },

  mutations: {
    setDeal(state, value: GooseItemPrice): void {
      state.deal = value
    },
    setDeals(state, value: GooseItemPrice[]): void {
      state.deals = value
    },
    setLoading(state, value: boolean): void {
      state.loading = value
    },
    setEmail(state, value: string): void {
      state.email = value
    },
    setEmailError(state, value: boolean): void {
      state.emailError = value
    },
    setCyberAlarm(state, value: string): void {
      state.hasCyberAlarm = value
    },
    setCouponCode(state, value: string): void {
      state.couponCode = value
    },
    setEstimate(state, value: EstimateCreateResult): void {
      state.estimate = value
    },
    setCurrency(state, value: string): void {
      state.currencyCode = value
      console.log(`Config: ${state.currencyCode} (${state.country})`)
    },
    setCountry(state, value: string): void {
      state.country = value
      state.currencyCode = getCurrency(value)
      console.log(`Config: ${state.currencyCode} (${state.country})`)
    },
    setSignupToken(state, value: string): void {
      state.signuptoken = value
    },
  },

  actions: {
    async handleSignup({ dispatch, commit, state }, { query }) : Promise<void> {
      // Check query parameters.
      if (query.status === 'success' && query.cb_payment_intent_id) {
        commit('setLoading', true)
        await dispatch('signupIdeal', { paymentIntentId: query.cb_payment_intent_id })
        commit('setLoading', false)
      }
    },

    async fetchCountry({ commit, state }): Promise<void> {
      const countryData = await request('GET', '/users/me/ip', {})
      commit('setCountry', countryData.data.country_code)
    },

    async fetchDeals({ commit, state, dispatch }): Promise<void> {
      commit('setLoading', true)

      const data = await request('GET', `/item-prices?currency_code=${state.currencyCode}`)
      const deals = getCamelCase<GooseItemPrice[]>(data.data)

      gaViewItemList(deals)

      commit('setDeals', deals)

      // Preselect Lifetime Tier 1 Deal
      const preselect = state.deals.find((deal) => deal.id === 'GOOSE-VPN-Lifetime-Tier-1-EUR-Yearly') || state.deals[0]
      console.log('Preselecting deal', preselect)
      commit('setDeal', preselect)
      dispatch('fetchOrder')

      commit('setLoading', false)
    },

    async fetchOrder({ commit, state }): Promise<void> {
      commit('setEstimate', null)

      const data = await request('POST', '/estimate',
        {
          item_price_id: getItemPrices(state),
          coupon: state.couponCode || undefined,
          country: state.country,
        },
      )

      const estimate = getCamelCase<EstimateCreateResult>(data.data)
      commit('setEstimate', estimate)
    },

    async switchCurrencyCode({ commit, state, dispatch }): Promise<void> {
      const currencyCodes = ['EUR', 'GBP', 'USD']
      const index = currencyCodes.indexOf(state.currencyCode)
      const newCurrency = currencyCodes[(index + 1) % currencyCodes.length]

      commit('setCurrency', newCurrency)
      dispatch('fetchDeals')
    },

    async selectDeal({ commit, state, dispatch }, deal: GooseItemPrice): Promise<void> {
      commit('setDeal', deal)
      dispatch('fetchOrder')
    },

    async toggleCyberAlarm({ commit, state, dispatch }): Promise<void> {
      commit('setCyberAlarm', !state.hasCyberAlarm)
      dispatch('fetchOrder')
    },

    async addCouponCode({ commit, state, dispatch }, code: string): Promise<void> {
      commit('setCouponCode', code)
      dispatch('fetchOrder')
    },

    async setEmailAddress({ commit, state }, email: string): Promise<void> {
      commit('setEmailError', false)
      commit('setEmail', email)

      const params = new URLSearchParams([['email', email]])
      const data = await request('GET', `/email-exists?${params}`)
      const { result } = getCamelCase<{ result: number }>(data.data)

      if (result) {
        commit('setEmailError', true)
      }
    },

    async createPaymentIntent({ commit, state }, { method }): Promise<void> {
      const data = await request('POST', '/payment-intent', {
        amount: state.estimate.invoiceEstimate.total,
        currency_code: state.estimate.invoiceEstimate.currencyCode,
        payment_method_type: method,
        email: state.email,
        country: state.country,
        language: i18n.locale,
        item_price_id: getItemPrices(state),
        coupon: state.couponCode || undefined,
      })
      return data.data
    },

    async signup({ commit, state }, { method, data }): Promise<void> {
      const email = state.email
      const res = await request('POST', '/signup', {
        email,
        item_price_id: getItemPrices(state),
        language: i18n.locale,
        coupon: state.couponCode || undefined,
        country: state.country,
        payment_method_type: method,
        payment_method_data: data,
      })
      const subscription = getCamelCase<SubscriptionCreateResult>(res.data.subscription)
      gaPurchase(subscription);

      // This is a hack to get the referral code (FirstPromoter) to work
      (window as any)?.fpr('referral', { email, uid: subscription.customer.id })
      return res.data.token
    },

    async signupIdeal({ commit, state }, { paymentIntentId }) : Promise<void> {
      const res = await request('POST', '/signup-ideal', { payment_intend_id: paymentIntentId })
      if (res.data.subscription) {
        const subscription = getCamelCase<SubscriptionCreateResult>(res.data.subscription)
        gaPurchase(subscription)
      }
      commit('setSignupToken', res.data.token)
      return res.data.token
    },

    async signupViaActivationCode({ commit, state }, { email, code }): Promise<void> {
      const res = await request('POST', '/activation_codes/signup', {
        email,
        code,
      })
      return res.data.token
    },

    async createChargebeeAccountIfNotExists({ commit, state, rootState }): Promise<void> {
      console.log(rootState.users.user.chargebeeId)
      if (!rootState.users.user.chargebeeId) {
        const res = await request('POST', '/create-chargebee-account', {
          country: state.country,
        })

        const updatedUser = parseUserResponse(res.data)
        commit('setUser', updatedUser)
      }
    },

    async selectSubscription({ commit, state }): Promise<void> {
      const res = await request('POST', '/select-subscription', {
        item_price_id: getItemPrices(state),
        coupon: state.couponCode || undefined,
        country: state.country,
      })
      const subscription = getCamelCase<SubscriptionCreateResult>(res.data)
      gaPurchase(subscription)
      return res.data.token
    },

    async saveState({ commit, state }): Promise<void> {
      localStorage.setItem(STATE_KEY, JSON.stringify(state))
    },
  },
}

// Helper functions
function getItemPrices(state): string[] {
  const result: string[] = []
  result.push(state.deal.id)
  if (state.hasCyberAlarm) {
    result.push(state.deal.applicableItemPrices[0].id)
  }
  return result
}

function getCurrency(country: string): string {
  if (country === 'UK') {
    return 'GBP'
  } else if (EURO_COUNTRIES.includes(country)) {
    return 'EUR'
  } else {
    return 'USD'
  }
}
