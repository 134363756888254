






import { Component, Vue } from 'vue-property-decorator'
import { Action } from 'vuex-class'
declare const Chargebee: any

@Component
export default class PaymentIDeal extends Vue {
  @Action signup;
  @Action createPaymentIntent;

  async onSubmit(): Promise<void> {
    try {
      const chargebeeInstance = Chargebee.getInstance()
      chargebeeInstance.load('ideal')
      const intent = await chargebeeInstance.handlePayment('ideal', {
        paymentIntent: async () => {
          const data = await this.createPaymentIntent({ method: 'ideal' })
          return data.payment_intent
        },
        redirectMode: true,
      })
    } catch (e) {
      // All kinds of errors are thrown here, log the error and show general message.
      console.warn('Error processing iDeal payment:', e)
      throw new Error(this.$t('signUp.iDealError').toString())
    }
  }
}
