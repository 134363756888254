// Notice that we did not add all types, we replaced some with "any"

export interface ItemPrice {
  id: string;
  name: string;
  itemFamilyId?: string;
  itemId?: string;
  description?: string;
  status?: string;
  externalName?: string;
  pricingModel: string;
  price?: number;
  priceInDecimal?: string;
  period?: number;
  currencyCode: string;
  periodUnit?: string;
  trialPeriod?: number;
  trialPeriodUnit?: string;
  trialEndAction?: string;
  shippingPeriod?: number;
  shippingPeriodUnit?: string;
  billingCycles?: number;
  freeQuantity: number;
  freeQuantityInDecimal?: string;
  channel?: string;
  resourceVersion?: number;
  updatedAt?: number;
  createdAt: number;
  archivedAt?: number;
  invoiceNotes?: string;
  isTaxable?: boolean;
  metadata?: any;
  itemType?: string;
  archivable?: boolean;
  parentItemId?: string;
  showDescriptionInInvoices?: boolean;
  showDescriptionInQuotes?: boolean;
}
export interface GooseItemPriceTranslatedMetadata {
  title?: string
  subtitle?: string
  savings?: string
  bestValue?: string
  productUsp: string
  productFeatures: string[]
}

export interface GooseItemPriceMetadata {
  desktopOrder?: number
  mobileOrder?: number
  translations?: Record<string, GooseItemPriceTranslatedMetadata>
}

export interface GooseItemPrice extends ItemPrice {
  applicableItemPrices?: ItemPrice[]
  charges?: ItemPrice[]
  metadata?: GooseItemPriceMetadata
}

export class LineItem {
  id?: string;
  subscriptionId?: string;
  dateFrom: number;
  dateTo: number;
  unitAmount: number;
  quantity?: number;
  amount?: number;
  pricingModel?: string;
  isTaxed: boolean;
  taxAmount?: number;
  taxRate?: number;
  unitAmountInDecimal?: string;
  quantityInDecimal?: string;
  amountInDecimal?: string;
  discountAmount?: number;
  itemLevelDiscountAmount?: number;
  description: string;
  entityDescription: string;
  entityType: string;
  taxExemptReason?: string;
  entityId?: string;
  customerId?: string;
}

export class Discount {
  amount: number;
  description?: string;
  entityType: string;
  entityId?: string;
}

export class Tax {
  name: string;
  amount: number;
  description?: string;
}

export class LineItemTax {
  lineItemId?: string;
  taxName: string;
  taxRate: number;
  isPartialTaxApplied?: boolean;
  isNonComplianceTax?: boolean;
  taxableAmount: number;
  taxAmount: number;
  taxJurisType?: string;
  taxJurisName?: string;
  taxJurisCode?: string;
  taxAmountInLocalCurrency?: number;
  localCurrencyCode?: string;
}

export class LineItemTier {
  lineItemId?: string;
  startingUnit: number;
  endingUnit?: number;
  quantityUsed: number;
  unitAmount: number;
  startingUnitInDecimal?: string;
  endingUnitInDecimal?: string;
  quantityUsedInDecimal?: string;
  unitAmountInDecimal?: string;
}

export class LineItemDiscount {
  lineItemId: string;
  discountType: string;
  couponId?: string;
  entityId?: string;
  discountAmount: number;
}

export class InvoiceEstimate {
  recurring: boolean;
  priceType: string;
  currencyCode: string;
  subTotal: number;
  total?: number;
  creditsApplied?: number;
  amountPaid?: number;
  amountDue?: number;
  lineItems?: Array<LineItem>;
  discounts?: Array<Discount>;
  taxes?: Array<Tax>;
  lineItemTaxes?: Array<LineItemTax>;
  lineItemTiers?: Array<LineItemTier>;
  lineItemDiscounts?: Array<LineItemDiscount>;
  roundOffAmount?: number;
  customerId?: string;
}

export declare class SubscriptionEstimate {
  id?: string;
  currencyCode: string;
  status?: string;
  trialEndAction?: string;
  nextBillingAt?: number;
  pauseDate?: number;
  resumeDate?: number;
}

export class Estimate {
  createdAt: number;
  subscriptionEstimate?: SubscriptionEstimate;
  invoiceEstimate?: InvoiceEstimate;
}

export interface EstimateCreateResult extends Estimate {
  appliedCouponCode: string;
}

export interface Amount {
  value: string;
  currency: string;
}

export interface Image {
  size1x: string;
  size2x: string;
  svg: string;
}

export interface Self {
  href: string;
  type: string;
}

export interface Links {
  self: Self;
}

export interface Subscription {
  id: string;
  currencyCode: string;
  planId: string;
  planQuantity: number;
  planUnitPrice?: number;
  setupFee?: number;
  billingPeriod?: number;
  billingPeriodUnit?: string;
  startDate?: number;
  trialEnd?: number;
  remainingBillingCycles?: number;
  poNumber?: string;
  autoCollection?: string;
  planQuantityInDecimal?: string;
  planUnitPriceInDecimal?: string;
  customerId: string;
  planAmount?: number;
  planFreeQuantity?: number;
  status: string;
  trialStart?: number;
  trialEndAction?: string;
  currentTermStart?: number;
  currentTermEnd?: number;
  nextBillingAt?: number;
  createdAt?: number;
  startedAt?: number;
  activatedAt?: number;
  giftId?: string;
  contractTermBillingCycleOnRenewal?: number;
  overrideRelationship?: boolean;
  pauseDate?: number;
  resumeDate?: number;
  cancelledAt?: number;
  cancelReason?: string;
  affiliateToken?: string;
  createdFromIp?: string;
  resourceVersion?: number;
  updatedAt?: number;
  hasScheduledAdvanceInvoices: boolean;
  hasScheduledChanges: boolean;
  paymentSourceId?: string;
  planFreeQuantityInDecimal?: string;
  planAmountInDecimal?: string;
  cancelScheduleCreatedAt?: number;
  offlinePaymentMethod?: string;
  channel?: string;
  netTermDays?: number;
  subscriptionItems?: Array<any>;
  itemTiers?: Array<any>;
  chargedItems?: Array<any>;
  dueInvoicesCount?: number;
  dueSince?: number;
  totalDues?: number;
  mrr?: number;
  exchangeRate?: number;
  baseCurrencyCode?: string;
  addons?: Array<any>;
  eventBasedAddons?: Array<any>;
  chargedEventBasedAddons?: Array<any>;
  coupon?: string;
  coupons?: Array<any>;
  shippingAddress?: any;
  referralInfo?: any;
  invoiceNotes?: string;
  metaData?: any;
  metadata?: any;
  deleted: boolean;
  changesScheduledAt?: number;
  contractTerm?: any;
  cancelReasonCode?: string;
  freePeriod?: number;
  freePeriodUnit?: string;
  createPendingInvoices?: boolean;
  autoCloseInvoices?: boolean;
}

export interface Invoice {
  id: string;
  poNumber?: string;
  customerId: string;
  subscriptionId?: string;
  recurring: boolean;
  status: string;
  vatNumber?: string;
  priceType: string;
  date?: number;
  dueDate?: number;
  netTermDays?: number;
  exchangeRate?: number;
  currencyCode: string;
  total?: number;
  amountPaid?: number;
  amountAdjusted?: number;
  writeOffAmount?: number;
  creditsApplied?: number;
  amountDue?: number;
  paidAt?: number;
  dunningStatus?: string;
  nextRetryAt?: number;
  voidedAt?: number;
  resourceVersion?: number;
  updatedAt?: number;
  subTotal: number;
  subTotalInLocalCurrency?: number;
  totalInLocalCurrency?: number;
  localCurrencyCode?: string;
  tax: number;
  firstInvoice?: boolean;
  newSalesAmount?: number;
  hasAdvanceCharges?: boolean;
  termFinalized: boolean;
  isGifted: boolean;
  generatedAt?: number;
  expectedPaymentDate?: number;
  amountToCollect?: number;
  roundOffAmount?: number;
  lineItems?: Array<LineItem>;
  discounts?: Array<Discount>;
  lineItemDiscounts?: Array<LineItemDiscount>;
  taxes?: Array<Tax>;
  lineItemTaxes?: Array<LineItemTax>;
  lineItemTiers?: Array<LineItemTier>;
  linkedPayments?: Array<any>;
  dunningAttempts?: Array<any>;
  appliedCredits?: Array<any>;
  adjustmentCreditNotes?: Array<any>;
  issuedCreditNotes?: Array<any>;
  linkedOrders?: Array<any>;
  notes?: Array<any>;
  shippingAddress?: any;
  billingAddress?: any;
  einvoice?: any;
  paymentOwner?: string;
  voidReasonCode?: string;
  deleted: boolean;
  vatNumberPrefix?: string;
  channel?: string;
}

export interface SubscriptionCreateResult {
  subscription: Subscription;
  invoice: Invoice
  customer: any;
  unbilledCharges: any;
  appliedCouponCode: string;
}
