

























import { Component, Vue } from 'vue-property-decorator'
import { Action } from 'vuex-class'

@Component
export default class SignUp extends Vue {
  @Action fetchCountry;
  @Action fetchDeals;
  isMountedData = false;

  async mounted(): Promise<void> {
    await Promise.all([
      this.fetchCountry(),
      this.fetchDeals(),
    ])
    this.isMountedData = true
  }
}
