















import { Component, Vue } from 'vue-property-decorator'
import { Action, Getter } from 'vuex-class'

@Component
export default class SelectSubscriptionPage extends Vue {
  @Getter loading: boolean;
  @Action fetchCountry;
  @Action fetchDeals;
  @Action createChargebeeAccountIfNotExists;
  @Action fetchUserPaymentMethods;

  async mounted(): Promise<void> {
    // It could be that the state is already loaded when returning from redirect
    if (this.loading) {
      await Promise.all([
        this.fetchCountry(),
        this.fetchDeals(),
      ])
      await this.createChargebeeAccountIfNotExists()
      await this.fetchUserPaymentMethods()
    }
  }
}
